.totalInfoHoverStyle {
  cursor: pointer
}

.totalInfoHoverStyle:hover {
  text-underline-offset: 4px;
  text-decoration: underline;
}

.modal {
  width: 516px;
  min-width: 516px;
  margin-left: -8px;
}

.content-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 20px 20px 32px 20px;
}
.content-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-container > div:nth-child(1) {
  gap: 20px;
}
.content-container > div:nth-child(2) {
  gap: 12px;
  align-items: flex-start;
  padding-right: 20px;
}

.link {
  font-family: GothamMedium;
  text-decoration: underline;
  cursor: pointer;
}
.link:hover {
  color: var(--neutral800);
}